import {createSelector} from 'reselect';

export const tokenAuthSelector = createSelector(
    (state) => state.auth,
    app => app.token,
);

export const userRolesSelector = createSelector(
    (state) => state.auth,
    app => app.userRoles,
);

export const profileSelector = createSelector(
    (state) => state.auth,
    app => app.profile,
);

export const roleListrequestSelector = createSelector(
    (state) => state.auth,
    app => app.roleListrequest,
);

export const employeesSelector = createSelector(
    (state) => state.auth,
    auth => auth.accountLists,
);