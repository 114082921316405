import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SettingService from "services/SettingService";
import openNotification from "../../utils/notification";
import Utils from "../../utils";

export const getStoreUtility = createAsyncThunk(
  "setting/getStoreUtility",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetAllStoreUtility();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addStoreUtility = createAsyncThunk(
  "setting/addStoreUtility",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await SettingService.AddStoreUtility(data);
      if(response.data.ok) {
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateStoreUtility = createAsyncThunk(
  "setting/updateStoreUtility",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await SettingService.UpdateStoreUtility(data);
      if(response.data.ok) {
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const delStoreUtility = createAsyncThunk(
    "setting/delStoreUtility",
    async (id, { rejectWithValue, dispatch }) => {
      try {
        const response = await SettingService.DeleteStoreUtility(id);
        if(response.data.ok) {
          openNotification(
            "success",
            Utils.setLocale("admin.settings.popup.notification.success"),
            Utils.setLocale("admin.settings.popup.notification.title")
          );
        }
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
);

const initialState = {
  loading: false,
  utilityList: [],
  utilitySource: [],
};
export const StoreUtilitySlice = createSlice({
  name: "storeUtility",
  initialState,
  reducers: {    showLoading: (state) => {
      state.loading = true;
    },
    setUtilityData: (state, {payload}) => {
      state.utilityList = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoreUtility.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStoreUtility.fulfilled, (state, action) => {
        state.loading = false;
        state.utilityList = action.payload;
        state.utilitySource = action.payload;
      })
      .addCase(getStoreUtility.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addStoreUtility.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStoreUtility.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addStoreUtility.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreUtility.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStoreUtility.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreUtility.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(delStoreUtility.pending, (state) => {
        state.loading = true;
      })
      .addCase(delStoreUtility.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(delStoreUtility.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setUtilityData } = StoreUtilitySlice.actions;

export default StoreUtilitySlice.reducer;
