import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import notificationService from "services/notificationService";
export const getNotificationApi = createAsyncThunk(
  "notification/getNotificationApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await notificationService.getNotificationApi(data);
      return response;
      
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const readAllApi = createAsyncThunk(
  "notification/readAllApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await notificationService.readAllApi(data);
      return response.data;
      
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const delAllApi = createAsyncThunk(
  "notification/delAllApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await notificationService.delAllApi(data);
      return response.data;
      
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const saveFirebaseTokenApi = createAsyncThunk(
  "notification/saveFirebaseTokenApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await notificationService.saveFirebaseTokenApi(data);
      return response.data;
      
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const readNotificationApi = createAsyncThunk(
  "notification/readNotificationApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, notiId } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await notificationService.readNotificationApi(notiId);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const saveLabelStarApi = createAsyncThunk(
  "notification/saveLabelStarApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await notificationService.saveLabelStarApi(data);
      if (onSuccess) onSuccess(response);
      return response.data;
      
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const delMultiNotiApi = createAsyncThunk(
  "notification/delMultiNotiApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await notificationService.delMultiNotiApi(data);
      if (onSuccess) onSuccess(response);
      return response.data;
      
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  notificationData: [],
  countNotRead: 0,
};
export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotificationApi.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationData = action.payload.data;
        state.countNotRead = action.payload.countNotRead;
      })
      .addCase(getNotificationApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(readAllApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(readAllApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(readAllApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(delAllApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delAllApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(delAllApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(saveFirebaseTokenApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveFirebaseTokenApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(saveFirebaseTokenApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(readNotificationApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(readNotificationApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(readNotificationApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(delMultiNotiApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delMultiNotiApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(delMultiNotiApi.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = notificationSlice.actions;

export default notificationSlice.reducer;
