import fetch from "auth/FetchInvoice";
import axiosInstance from "auth/FetchInterceptor";
import {
  GET_ALL_TEMPLATE_INVOICE,
  GET_STORE_INVOICE,
  LOGIN_INVOICE,
  UPSET_STORE_INVOICE,
  SIGNATURE_USB_HASH_INVOICE,
  CREATE_USB_HASH_INVOICE,
  UPSET_ORDER_INVOICE,
  GET_ORDER_INVOICE,
  GENERATE_INVOICE,
  SIGN_ORDER_INVOICE,
  GET_CUSTOMER_INVOICE,
  CREATE_PDF_INVOICE,
  GET_INVOICE_UUID,
  CREATE_HSM_INVOICE,
} from "../constants/ApiConstant";
import axios from "axios";

const SInvoiceService = {};

SInvoiceService.loginSInvoice = (data) => {
  return fetch.post(LOGIN_INVOICE, data);
};

SInvoiceService.getAllInvoiceTemplates = (taxCode) => {
  return fetch.post(GET_ALL_TEMPLATE_INVOICE, {
    taxCode: taxCode,
    invoiceType: "all",
  });
};

SInvoiceService.createUSBHash = (data) => {
  return fetch.post(`${CREATE_USB_HASH_INVOICE}/${data?.sellerInfo?.sellerTaxCode}`, data);
};

SInvoiceService.signatureUSBInvoice = (data) => {
  return fetch.post(SIGNATURE_USB_HASH_INVOICE, data);
};

SInvoiceService.createHSMInvoice = (data) => {
  return fetch.post(`${CREATE_HSM_INVOICE}/${data?.sellerInfo?.sellerTaxCode}`, data);
};

SInvoiceService.getStoreInvoice = (storeId) => {
  return axiosInstance.get(`${GET_STORE_INVOICE}/${storeId}`);
};

SInvoiceService.upsetStoreInvoice = (data) => {
  return axiosInstance.post(UPSET_STORE_INVOICE, data);
};

SInvoiceService.getOrderInvoice = (orderId) => {
  return axiosInstance.get(`${GET_ORDER_INVOICE}/${orderId}`);
};

SInvoiceService.upsetOrderInvoice = (data) => {
  return axiosInstance.post(UPSET_ORDER_INVOICE, data);
};

SInvoiceService.generateInvoice = (data) => {
  return axiosInstance.post(GENERATE_INVOICE, data);
};

SInvoiceService.signInvoice = (data) => {
  return axiosInstance.post(SIGN_ORDER_INVOICE, data);
};

SInvoiceService.getCustomerInvoice = (userId) => {
  return axiosInstance.get(`${GET_CUSTOMER_INVOICE}/${userId}`);
};

SInvoiceService.createPdfInvoice = (data) => {
  var formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return fetch.post(CREATE_PDF_INVOICE, formBody, {
    headers: {
      "Content-type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  });
};

SInvoiceService.getInvoiceUuid = (data) => {
  var formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return fetch.post(GET_INVOICE_UUID, formBody, {
    headers: {
      "Content-type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  });
};

SInvoiceService.searchBusiness = (taxCode) => {
  return axiosInstance.get(`/v2/business/${taxCode}`, {baseURL: process.env.REACT_APP_VIETQR});
}

SInvoiceService.getCountryList = () => {
  return axios.get("https://restcountries.com/v3.1/all");
};

SInvoiceService.getCertificateInfo = (data) => {
  return fetch.get(
    `/management-signature/search?page=0&size=10&tenantBranchId.equals=&taxCode.contains=${data.taxCode}&signatureType.equals=${data.authTypeInvoice}`,
    {baseURL: 'https://vinvoice.viettel.vn/api/cluster4/services/einvoiceapplication/api'}
  );
};

export default SInvoiceService;