import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import SettingService from "services/SettingService";
import openNotification from "utils/notification";
import Utils from "utils"

export const getAllStoreCate = createAsyncThunk(
  "setting/getAllStoreCate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetAllStoreCate();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addStoreCate = createAsyncThunk(
  "setting/addStoreCate",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await SettingService.AddStoreCate(data);
      if(response.data.ok) {
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateStoreCate = createAsyncThunk(
  "setting/updateStoreCate",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await SettingService.UpdateStoreCate(data);
      if(response.data.ok) {
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const delStoreCate = createAsyncThunk(
  "setting/delStoreCate",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await SettingService.DeleteStoreCate(id);
      if(response.data.ok) {
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
const initialState = {
  loading: false,
  dataList: [],
  dataSource: [],
};
export const settingStoreCateSlice = createSlice({
  name: "settingStoreCate",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setDataList: (state, { payload }: PayloadAction & lt) => {
      state.dataList = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStoreCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStoreCate.fulfilled, (state, action) => {
        state.loading = false;
        state.dataList = action.payload;
        state.dataSource = action.payload;
      })
      .addCase(getAllStoreCate.rejected, (state, action) => {
        state.loading = false;
      })
      //add cate
      .addCase(addStoreCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStoreCate.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addStoreCate.rejected, (state, action) => {
        state.loading = false;
      })
      //update cate
      .addCase(updateStoreCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStoreCate.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreCate.rejected, (state, action) => {
        state.loading = false;
      })
      //del cate
      .addCase(delStoreCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(delStoreCate.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(delStoreCate.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setDataList } = settingStoreCateSlice.actions;

export default settingStoreCateSlice.reducer;
