import OrderService from "services/orderService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

export const orderSearchApi = createAsyncThunk(
    "store/orderSearchApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await OrderService.OrderSearch(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const orderSearchProductApi = createAsyncThunk(
    "store/orderSearchProductApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await OrderService.OrderSearchProduct(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const orderExportExelApi = createAsyncThunk(
    "store/orderExportExelApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.OrderExportExel(data);
            if (onSuccess) onSuccess(response);
            return response;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getOrderDetail = createAsyncThunk(
    "order/getOrderDetail",
    async (id, { rejectWithValue }) => {
        try {
            const response = await OrderService.getOrderDetail(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getTableOrderApi = createAsyncThunk(
    "order/getTableOrderApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.tableSearchOrder(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getTableEmployeeOrderApi = createAsyncThunk(
    "order/getTableEmployeeOrderApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await OrderService.tableSearchEmployeeOrder(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getTableEmployeeOrderApiV2 = createAsyncThunk(
    "order/getTableEmployeeOrderApiV2",
    async (data, { rejectWithValue }) => {
        try {
            const response = await OrderService.tableSearchEmployeeOrderV2(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getSummaryEmployeeOrderApi = createAsyncThunk(
    "order/getSummaryEmployeeOrderApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await OrderService.tableSummaryEmployeeOrder(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


export const changeOrderItemAll = createAsyncThunk(
    "order/changeOrderItemAll",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.orderChangeStatusAll(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeConfirmOrder = createAsyncThunk(
    "order/employeeConfirmOrder",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.orderConfirm(payload.orderId);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeChangeCashOrder = createAsyncThunk(
    "order/employeeChangeCashOrder",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.changeCashOrder(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeChangeTypeOrder = createAsyncThunk(
    "order/employeeChangeTypeOrder",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.changeTypeOrder(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeePrePaymentOrder = createAsyncThunk(
    "order/employeePrePaymentOrder",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.prePaymentOrder(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeGetOrderSetting = createAsyncThunk(
    "order/employeeGetOrderSetting",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.getOrderSetting(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


export const employeeCancelOrder = createAsyncThunk(
    "order/employeeCancelOrder",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.orderReject(payload.orderId);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeePaymentConfirmOrder = createAsyncThunk(
    "order/employeePaymentConfirmOrder",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.orderPaymentConfirm(payload.orderDetail);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeePaymentRequestOrder = createAsyncThunk(
    "order/employeePaymentRequestOrder",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.orderPaymentRequest(payload.orderDetail);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeCancelOrderItem = createAsyncThunk(
    "order/employeeCancelOrderItem",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.cancelOrderItem(payload.orderItemId);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeChangeStatusOrderItem = createAsyncThunk(
    "order/employeeChangeStatusOrderItem",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.changeStatusOrderItem(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeChangeOrderCustomer = createAsyncThunk(
    "order/employeeChangeOrderCustomer",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.changeOrderCustomer(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeCreateOrderItem = createAsyncThunk(
    "order/employeeCreateOrderItem",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.createOrderItem(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeCreateItemExternal = createAsyncThunk(
    "order/employeeCreateItemExternal",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.creatEItemExternal(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeUpdateItemExternal = createAsyncThunk(
    "order/employeeUpdateItemExternal",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.updateItemExternal(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeProductUpdateAll = createAsyncThunk(
    "order/employeeProductUpdateAll",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.changeProductStatus(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeCreateOrder = createAsyncThunk(
    "order/employeeCreateOrder",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.createOrder(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeChangeOrderItemPrint = createAsyncThunk(
    "order/employeeChangeOrderItemPrint",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.changePrintStatus(payload.orderId);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getStoreProductByCategory = createAsyncThunk(
    "order/getStoreProductByCategory",
    async (data, { rejectWithValue }) => {
        try {
            const response = await OrderService.GetStoreProductByCategory(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeExtractOrder = createAsyncThunk(
    "order/employeeExtractOrder",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.extractOrder(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeGroupOrder = createAsyncThunk(
    "order/employeeGroupOrder",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.groupOrder(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const sortOrderItems = createAsyncThunk(
    "order/sortOrderItems",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.sortOrderItems(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const employeeUpdateOrderPaid = createAsyncThunk(
    "order/employeeUpdateOrderPaid",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.updateOrderPaid(payload.orderId);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    orderList: [],
    orderTableList: [],
    orderProductList: [],
    orderProductComplete: [],
    orderSummary: [],
    orderData: {},
    storeProduct: [],
    storeProductAll: [],
    orderStoreSetting: {},
    totalCount: 0,
};

const orderSlice = createSlice(
    {
        name: "order",
        initialState,
        reducers: {
            showLoading: (state) => {
                state.loading = true;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(orderSearchApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(orderSearchApi.fulfilled, (state, action) => {
                    state.loading = false;
                    state.orderList = action.payload;
                })
                .addCase(orderSearchApi.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(orderSearchProductApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(orderSearchProductApi.fulfilled, (state, action) => {
                    state.loading = false;
                    const {products, productsCompleted} = action.payload;
                    state.orderProductList = products.data;
                    state.totalCount = products.totalCount;
                    state.orderProductComplete = productsCompleted;
                })
                .addCase(orderSearchProductApi.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(orderExportExelApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(orderExportExelApi.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(orderExportExelApi.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getTableOrderApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getTableOrderApi.fulfilled, (state, action) => {
                    state.loading = false;
                    state.orderTableList = action.payload;
                })
                .addCase(getTableOrderApi.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getTableEmployeeOrderApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getTableEmployeeOrderApi.fulfilled, (state, action) => {
                    state.loading = false;
                    state.orderList = action.payload;
                    state.totalCount = action.payload.totalCount;
                })
                .addCase(getTableEmployeeOrderApi.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getTableEmployeeOrderApiV2.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getTableEmployeeOrderApiV2.fulfilled, (state, action) => {
                    state.loading = false;
                    state.orderList = action.payload.employeeOrders.data;
                    state.totalCount = action.payload.employeeOrders.totalCount;
                    state.orderSummary = action.payload.orderSummary;
                })
                .addCase(getTableEmployeeOrderApiV2.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getStoreProductByCategory.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getStoreProductByCategory.fulfilled, (state, action) => {
                    state.loading = false;
                    state.storeProduct = action.payload;
                    const productsAll = [];
                    action.payload.forEach(cat => cat.products.forEach(product => productsAll.push(product)));
                    state.storeProductAll = productsAll;
                })
                .addCase(getStoreProductByCategory.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(changeOrderItemAll.pending, (state) => {
                    state.loading = true;
                })
                .addCase(changeOrderItemAll.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(changeOrderItemAll.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeCancelOrder.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeeCancelOrder.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeCancelOrder.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeePaymentConfirmOrder.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeePaymentConfirmOrder.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeePaymentConfirmOrder.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeePaymentRequestOrder.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeePaymentRequestOrder.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeePaymentRequestOrder.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeConfirmOrder.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeeConfirmOrder.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeConfirmOrder.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeCancelOrderItem.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeeCancelOrderItem.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeCancelOrderItem.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeChangeStatusOrderItem.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeeChangeStatusOrderItem.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeChangeStatusOrderItem.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeChangeOrderCustomer.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeeChangeOrderCustomer.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeChangeOrderCustomer.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeCreateOrderItem.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeeCreateOrderItem.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeCreateOrderItem.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getOrderDetail.pending, (state) => {
                })
                .addCase(getOrderDetail.fulfilled, (state, action) => {
                    state.orderData = action.payload;
                })
                .addCase(getOrderDetail.rejected, (state, action) => {
                })
                .addCase(employeeCreateOrder.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeeCreateOrder.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeCreateOrder.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getSummaryEmployeeOrderApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getSummaryEmployeeOrderApi.fulfilled, (state, action) => {
                    state.loading = false;
                    state.orderSummary = action.payload;
                })
                .addCase(getSummaryEmployeeOrderApi.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeProductUpdateAll.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeeProductUpdateAll.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeProductUpdateAll.rejected, (state, action) => {
                    state.loading = false;
                }).addCase(employeeChangeCashOrder.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeeChangeCashOrder.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeChangeCashOrder.rejected, (state, action) => {
                    state.loading = false;
                }).addCase(employeeChangeTypeOrder.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeeChangeTypeOrder.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeChangeTypeOrder.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeePrePaymentOrder.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeePrePaymentOrder.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeePrePaymentOrder.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(employeeGetOrderSetting.pending, (state) => {
                    state.loading = true;
                })
                .addCase(employeeGetOrderSetting.fulfilled, (state, action) => {
                    state.loading = false;
                    state.orderStoreSetting = action.payload;
                })
                .addCase(employeeGetOrderSetting.rejected, (state, action) => {
                    state.loading = false;
                });
        },
    }
);

export const { showLoading } = orderSlice.actions;

export default orderSlice.reducer;