import axiosInstance from "auth/FetchInterceptor";
import {
  PROVINCE_BY_COUNTRY,
  DISTRICT_BY_PROVINCE,
  WARD_BY_DISTRICT,
} from "constants/ApiConstant";
const LocationService = {};

LocationService.provinceByCountry = function (id) {
  return axiosInstance.get(`${PROVINCE_BY_COUNTRY}/${id}`, null);
};
LocationService.districtByProvince = function (id) {
  return axiosInstance.get(`${DISTRICT_BY_PROVINCE}/${id}`, null);
};
LocationService.wardByCountry = function (id) {
  return axiosInstance.get(`${WARD_BY_DISTRICT}/${id}`, null);
};

export default LocationService;
