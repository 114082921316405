import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import store from "./store";
import history from "./history";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
// import mockServer from './mock'
import "./lang";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const environment = process.env.NODE_ENV;

if (environment !== "production") {
  // mockServer({ environment })
}

function App() {
  const { i18n } = useTranslation();
  return (
    <div className="App">
      <Provider store={store}>
        <HelmetProvider>
          <BrowserRouter history={history}>
            <Helmet
              titleTemplate="%s - Smenubook"
              defaultTitle="Smenubook"
              htmlAttributes={{ lang: i18n.language }}
            >
              <meta name="description" content="A Smenubook Application" />
            </Helmet>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={THEME_CONFIG.currentTheme}
              insertionPoint="styles-insertion-point"
            >
              <Layouts />
            </ThemeSwitcherProvider>
          </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </div>
  );
}

export default App;
