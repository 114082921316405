import axiosInstance from "auth/FetchInterceptor";
import {
  MOBILE_PRODUCT_DETAIL,
  PRODUCT_API,
  PRODUCT_CHANGE_STATE,
  PRODUCT_SEARCH_GRID, PRODUCTS_CHANGE_STATUS,
  PRODUCTS_COPY, PRODUCTS_DISCOUNT,
  PRODUCTS_GROUP_CATEGORY,
} from "constants/ApiConstant";
const ProductService = {};

ProductService.productSearchGrid = function (data) {
  return axiosInstance.post(PRODUCT_SEARCH_GRID, data);
};

ProductService.getProductById = function (id) {
  return axiosInstance.get(`${PRODUCT_API}/${id}`, null);
};
ProductService.getProductMobileById = function (id) {
  return axiosInstance.get(`${MOBILE_PRODUCT_DETAIL}/${id}`, null);
};
ProductService.addProduct = function (data) {
  return axiosInstance.post(PRODUCT_API, data);
};
ProductService.updateProduct = function (data) {
  return axiosInstance.put(PRODUCT_API, data);
};
ProductService.updateProductState = function (data) {
  return axiosInstance.put(PRODUCT_CHANGE_STATE, data);
};
ProductService.delProduct = function (id) {
  return axiosInstance.delete(`${PRODUCT_API}/id?id=${id}`, null);
};
ProductService.copyProducts = function (data) {
  return axiosInstance.post(PRODUCTS_COPY, data);
};

ProductService.groupCategory = function (data) {
  return axiosInstance.post(PRODUCTS_GROUP_CATEGORY, data);
};

ProductService.changeStatusProducts = function (data) {
  return axiosInstance.put(PRODUCTS_CHANGE_STATUS, data);
};

ProductService.discountProducts = function (data) {
  return axiosInstance.put(PRODUCTS_DISCOUNT, data);
};

export default ProductService;
