import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import postCommentService from "services/postCommentService";
import {cloneDeep} from "lodash";
import openNotification from "utils/notification";
import Utils from "utils";



export const getPostCommentApi = createAsyncThunk(
  "postComment/postCommentSearchGrid",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postCommentService.postCommentSearchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addPostCommentApi = createAsyncThunk(
  "postComment/addPostCommentApi",
  async (data, { rejectWithValue }) => {
    const { onSuccess } = data;
    try {
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await postCommentService.addPostCommentApi(payload);
      if (onSuccess) onSuccess(response);
      // openNotification(
      //   "success",
      //   Utils.setLocale("admin.settings.popup.notification.success"),
      //   Utils.setLocale("admin.settings.popup.notification.title")
      // );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updatePostCommentApi = createAsyncThunk(
  "postComment/updatePostCommentApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await postCommentService.updatePostCommentApi(data);
      if (onSuccess) onSuccess(response);
      // openNotification(
      //   "success",
      //   Utils.setLocale("admin.settings.popup.notification.success"),
      //   Utils.setLocale("admin.settings.popup.notification.title")
      // );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const likeCommentApi = createAsyncThunk(
  "postComment/LikeCommentApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await postCommentService.likeCommentApi(id);
      if (onSuccess) onSuccess(response);
      // openNotification(
      //   "success",
      //   Utils.setLocale("admin.settings.popup.notification.success"),
      //   Utils.setLocale("admin.settings.popup.notification.title")
      // );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const delCommentApi = createAsyncThunk(
  "postComment/delCommentApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await postCommentService.delCommentApi(id);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const replyCommentApi = createAsyncThunk(
  "postComment/replyCommentApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await postCommentService.replyCommentApi(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getRepliesForComment = createAsyncThunk(
  "postComment/getRepliesForComment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postCommentService.getRepliesForComment(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const toggleHideCommentApi = createAsyncThunk(
  "postComment/toggleHideCommentApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await postCommentService.toggleHideCommentApi(id);
      if (onSuccess) onSuccess(response);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  rootCommentList: [],
  commentReplyList: [],
  totalCount: 0,
};
export const postCommentSlice = createSlice({
  name: "postComment",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setRootCommentList : (state) => {
      state.rootCommentList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPostCommentApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPostCommentApi.fulfilled, (state, action) => {
        state.loading = false;
        state.rootCommentList = action.payload?.data;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(getPostCommentApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addPostCommentApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPostCommentApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addPostCommentApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updatePostCommentApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePostCommentApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updatePostCommentApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(likeCommentApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(likeCommentApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(likeCommentApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(delCommentApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delCommentApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(delCommentApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(replyCommentApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(replyCommentApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(replyCommentApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getRepliesForComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRepliesForComment.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getRepliesForComment.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(toggleHideCommentApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(toggleHideCommentApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(toggleHideCommentApi.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setRootCommentList} = postCommentSlice.actions;

export default postCommentSlice.reducer;
