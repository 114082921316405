import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SaasService from "services/SaasService";
import openNotification from "../../utils/notification";
import Utils from "../../utils";
import cloneDeep from "lodash";

export const getSubscriptionList = createAsyncThunk
(
  "saas/getSubscriptionList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SaasService.getSubscription(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getSubscriptionDetail = createAsyncThunk
(
  "saas/getSubscriptionDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await SaasService.getSubscriptionInfo(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const upSetSubscription = createAsyncThunk
(
  "saas/upSetSubscription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SaasService.upSetSubscription(data);
      openNotification('success', 'Thay đổi thông tin gói thành công', Utils.setLocale("admin.settings.popup.notification.title"));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getSubscriptionRegister = createAsyncThunk
(
  "saas/getSubscriptionRegister",
  async (_, { rejectWithValue }) => {
    try {
      const response = await SaasService.getSubscriptionRegister();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getSubscriptionHistory = createAsyncThunk
(
  "saas/getSubscriptionHistory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SaasService.getSubscriptionHistory(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const copySubscription = createAsyncThunk
(
  "saas/copySubscription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SaasService.copySubscription(data);
      openNotification('success', 'Sao chép thông tin gói thành công', Utils.setLocale("admin.settings.popup.notification.title"));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getUserSubscription = createAsyncThunk
(
  "saas/getUserSubscription",
  async (id, { rejectWithValue }) => {
    try {
        const response = await SaasService.getUserSubscription(id);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message || "Error");
    }
  }
);

export const getPartnerSaas = createAsyncThunk
(
  "saas/getPartnerSaas",
  async (_, { rejectWithValue }) => {
    try {
      const response = await SaasService.getPartnerSaas();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getTime = createAsyncThunk
(
  "saas/getTime",
  async (subscriptionId, { rejectWithValue }) => {
    try {
      const response = await SaasService.getTimeSubs(subscriptionId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const upSetUserSubscription = createAsyncThunk
(
  "saas/upSetUserSubscription",
  async (data, { rejectWithValue }) => {
    try {
      const {userSubscriptionId, onSuccess} = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SaasService.upSetUserSubscription(payload);
      openNotification(
        'success',
        userSubscriptionId ? 'Gia hạn gói thành công' : 'Tạo gói thành công',
        Utils.setLocale("admin.settings.popup.notification.title")
      );

      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const changeActive = createAsyncThunk
(
  "saas/changeActive",
  async (id, { rejectWithValue }) => {
    try {
      const response = await SaasService.changeActiveUserSub(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
    isLoading: false,
    subscriptionList: [],
    subscriptionHistory: [],
    subscriptionRegister: [],
    timeSubscription: [],
    partnerList: [],
    subInfo: {},
    userSubscription: {},
    totalCount: 0,
    totalAmount: 0,
};

export const saasSlice = createSlice({
  name: "saas",
  initialState,
  extraReducers: (builders) => {
    builders
    .addCase(getSubscriptionList.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getSubscriptionList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subscriptionList = action.payload;
    })
    .addCase(getSubscriptionList.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(getSubscriptionDetail.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getSubscriptionDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subInfo = action.payload;
    })
    .addCase(getSubscriptionDetail.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(upSetSubscription.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(upSetSubscription.fulfilled, (state) => {
      state.isLoading = false;
    })
    .addCase(upSetSubscription.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(getSubscriptionRegister.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getSubscriptionRegister.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subscriptionRegister = action.payload;
    })
    .addCase(getSubscriptionRegister.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(getSubscriptionHistory.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getSubscriptionHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subscriptionHistory = action.payload.data;
      state.totalCount = action.payload.totalCount;
      state.totalAmount = action.payload.totalAmount;
    })
    .addCase(getSubscriptionHistory.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(copySubscription.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(copySubscription.fulfilled, (state) => {
      state.isLoading = false;
    })
    .addCase(copySubscription.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(getUserSubscription.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getUserSubscription.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userSubscription = action.payload;
    })
    .addCase(getUserSubscription.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(getPartnerSaas.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getPartnerSaas.fulfilled, (state, action) => {
      state.isLoading = false;
      state.partnerList = action.payload;
    })
    .addCase(getPartnerSaas.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(getTime.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getTime.fulfilled, (state, action) => {
      state.isLoading = false;
      state.timeSubscription = action.payload;
    })
    .addCase(getTime.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(upSetUserSubscription.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(upSetUserSubscription.fulfilled, (state) => {
      state.isLoading = false;
    })
    .addCase(upSetUserSubscription.rejected, (state) => {
      state.isLoading = false;
    });
  },
  reducers: {
    clearUserSub: (state) => {
      state.userSubscription = {};
    },
  }
});

export const {clearUserSub} = saasSlice.actions;

export default saasSlice.reducer;
