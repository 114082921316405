import axiosInstance from "auth/FetchInterceptor";
import { GET_MY_STORE, STORE_API, STORE_CHANGE_STATE, STORE_SEARCH_GRID } from "constants/ApiConstant";
const StoreService = {};

StoreService.storeSearchGrid = function (data) {
  return axiosInstance.post(STORE_SEARCH_GRID, data);
};
StoreService.getMyStore = function (data) {
  return axiosInstance.get(GET_MY_STORE, data);
};
StoreService.getStoreById = function (id) {
  return axiosInstance.get(`${STORE_API}/${id}`, null);
};
StoreService.createStore = function (data) {
  return axiosInstance.post(STORE_API, data);
};
StoreService.updateStore = function (data) {
  return axiosInstance.put(STORE_API, data);
};
StoreService.updateStoreState = function (data) {
  return axiosInstance.put(STORE_CHANGE_STATE, data);
};
StoreService.delStore = function (id) {
  return axiosInstance.delete(`${STORE_API}/id?id=${id}`, null);
};

export default StoreService;
