export const SIGN_SERVER = "1";
export const CLOUD_CA = "4";
export const HSM = "3";
export const USB_TOKEN = "2";

export const AUTH_TYPE = [
  {value: SIGN_SERVER, title: "Chữ ký server"},
  {value: CLOUD_CA, title: "CloudCA"},
  {value: HSM, title: "HSM"},
  {value: USB_TOKEN, title: "USB-TOKEN"},
];

export const PAYMENT_METHOD = [
  "TM",
  "CK",
  "TM/CK",
];

export const COUNTRY_LIST = [
  {name: 'Việt Nam', phoneCode: '84', numcode: '704', currencyCode: 'VND', flag: 'https://flagcdn.com/w320/vn.png'}
]

export const SELECT_PLACEHOLDER = "--Lựa chọn--";