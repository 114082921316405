import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import {AUTHENTICATED_EMPLOYEE, AUTHENTICATED_ENTRY, AUTHENTICATED_ENTRY_ADMIN} from 'configs/AppConfig'

import { SYSTEM_ROLES } from 'constants/AuthConstant';

const PublicRoute = () => {

	const { token,userRoles } = useSelector(state => state.auth);
	let toPath = "";
	if (userRoles.includes(SYSTEM_ROLES.OperatorAdmin)) {
		toPath= AUTHENTICATED_ENTRY_ADMIN;
	} else if (userRoles.includes(SYSTEM_ROLES.PartnerAdmin) ||
			   userRoles.includes(SYSTEM_ROLES.StoreAdmin))	{
				toPath = AUTHENTICATED_ENTRY;
	} else if (userRoles.includes(SYSTEM_ROLES.StoreEmployee) ||
			userRoles.includes(SYSTEM_ROLES.KitchenEmployee)) {
		toPath = AUTHENTICATED_EMPLOYEE;
	}

	return token ? <Navigate className="ant-menu-item-selected"
	 					to={toPath}
	 				/> : <Outlet/>
}

export default PublicRoute