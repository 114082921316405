import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import SettingService from "services/SettingService";
import Utils from "utils";
import openNotification from "utils/notification";

export const getAllStoreBanner = createAsyncThunk(
  "setting/getAllStoreBanner",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetStoreBanner();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addStoreBanner = createAsyncThunk(
  "setting/addStoreBanner",
  async (data, { rejectWithValue }) => {
    const { onSuccess } = data;
    try {
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SettingService.AddStoreBanner(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateStoreBanner = createAsyncThunk(
  "setting/updateStoreBanner",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SettingService.UpdateStoreBanner(data);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const DelStoreBanner = createAsyncThunk(
  "setting/DelStoreBanner",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SettingService.DeleteStoreBanner(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
const initialState = {
  loading: false,
  bannerList: [],
  dataSource: [],
};
export const settingStoreBanner = createSlice({
  name: "settingBanner",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setDataList: (state, { payload }) => {
      state.bannerList = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStoreBanner.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStoreBanner.fulfilled, (state, action) => {
        state.loading = false;
        state.bannerList = action.payload;
        state.dataSource = action.payload;
      })
      .addCase(getAllStoreBanner.rejected, (state, action) => {
        state.loading = false;
      })
      //add cate
      .addCase(addStoreBanner.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStoreBanner.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addStoreBanner.rejected, (state, action) => {
        state.loading = false;
      })
      //update cate
      .addCase(updateStoreBanner.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStoreBanner.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreBanner.rejected, (state, action) => {
        state.loading = false;
      })
      //del cate
      .addCase(DelStoreBanner.pending, (state) => {
        state.loading = true;
      })
      .addCase(DelStoreBanner.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(DelStoreBanner.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setDataList } = settingStoreBanner.actions;

export default settingStoreBanner.reducer;
