import axiosInstance from "../auth/FetchInterceptor";
import {
  COPY_SUBSCRIPTION,
  GET_PARTNER_SAAS,
  GET_SUBSCRIPTION_DETAIL,
  GET_SUBSCRIPTIONS_HISTORY,
  GET_SUBSCRIPTIONS_LIST,
  GET_USER_SUBSCRIPTION,
  TIME_SUBSCRIPTION,
} from "../constants/ApiConstant";

const SaasService = {}

SaasService.getSubscription = (data) => {
  return axiosInstance.post(GET_SUBSCRIPTIONS_LIST, data);
};

SaasService.getSubscriptionHistory = (data) => {
  return axiosInstance.post(GET_SUBSCRIPTIONS_HISTORY, data);
};

SaasService.getSubscriptionRegister = () => {
  return axiosInstance.get(GET_SUBSCRIPTION_DETAIL);
};

SaasService.getSubscriptionInfo = (id) => {
  return axiosInstance.get(`${GET_SUBSCRIPTION_DETAIL}/${id}`);
};

SaasService.upSetSubscription = (data) => {
  return axiosInstance.put(GET_SUBSCRIPTION_DETAIL, data);
};

SaasService.copySubscription = (data) => {
  return axiosInstance.post(COPY_SUBSCRIPTION, data);
};

SaasService.getUserSubscription = (id) => {
  return axiosInstance.get(`${GET_USER_SUBSCRIPTION}/${id}`);
};

SaasService.changeActiveUserSub = (id) => {
  return axiosInstance.put(`${GET_USER_SUBSCRIPTION}/${id}`);
};

SaasService.getPartnerSaas = () => {
  return axiosInstance.get(GET_PARTNER_SAAS);
};

SaasService.getTimeSubs = (subscriptionId) => {
  return axiosInstance.get(`${TIME_SUBSCRIPTION}/${subscriptionId}`);
};

SaasService.upSetUserSubscription = (data) => {
  return axiosInstance.post(GET_SUBSCRIPTION_DETAIL, data);
};

export default SaasService;
