import axiosInstance from "auth/FetchInterceptor";
import { STORE_WIFI_API } from "constants/ApiConstant";
const StoreWifiService = {};

StoreWifiService.getStoreWifi = function (id) {
  return axiosInstance.get(`${STORE_WIFI_API}/${id}`, null);
};
StoreWifiService.createStoreWifi = function (data) {
  return axiosInstance.post(STORE_WIFI_API, data);
};
StoreWifiService.updateStoreWifi = function (data) {
  return axiosInstance.put(STORE_WIFI_API, data);
};
StoreWifiService.delStoreWifi = function (id) {
  return axiosInstance.delete(`${STORE_WIFI_API}/id?id=${id}`, null);
};

export default StoreWifiService;
