import axiosInstance from "auth/FetchInterceptor";
import {
    CHANGE_CUSTOMER,
    CHANGE_QUANTITY, CHANGE_TABLE,
    DELETE_CART,
    EMPLOYEE_UPSERT_EXTERNAL_SHOPPING_CART,
    EMPLOYEE_UPSERT_SHOPPING_CART, SHOPPING_CART_SORT,
    SHOPPING_CART_TABLE,
    UPSERT_SHOPPING_CART,
} from "constants/ApiConstant";
const TableCartService = {};

TableCartService.getGetTableCart = function (id) {
    return axiosInstance.get(`${SHOPPING_CART_TABLE}/${id}`, null);
};

TableCartService.upsetTableCart = function (data) {
    return axiosInstance.put(EMPLOYEE_UPSERT_SHOPPING_CART, data);
};

TableCartService.upsetTableCartExternal = function (data) {
    return axiosInstance.put(EMPLOYEE_UPSERT_EXTERNAL_SHOPPING_CART, data);
};


TableCartService.upsetShoppingCart = function (data) {
    return axiosInstance.put(UPSERT_SHOPPING_CART, data);
};

TableCartService.deleteShoppingCart = function (id) {
    return axiosInstance.post(`${DELETE_CART}/${id}`, null);
};

TableCartService.changeCartItemQuantity = function (data) {
    return axiosInstance.put(CHANGE_QUANTITY, data);
};

TableCartService.changeCustomerCart = function (data) {
    return axiosInstance.put(CHANGE_CUSTOMER, data);
};

TableCartService.changeTableCart = function (data) {
    return axiosInstance.put(CHANGE_TABLE, data);
};

TableCartService.sortCartItems = function (data) {
    return axiosInstance.put(SHOPPING_CART_SORT, data);
};

export default TableCartService;