import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CustomerService from "services/CustomerService";
// const uuidv4 = require("uuid/v4");
const { v4: uuidv4 } = require('uuid');
export const getCustomerApi = createAsyncThunk(
  "customer/getCustomerApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CustomerService.customerSearchGrid(data);
      const orgData = response.data;
      if (orgData && orgData.length > 0) {
        const newData = orgData.map(item => {
          return {...item, Uid: uuidv4() }
        })
        return newData;
      }
      return response.data;
      
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const getCustomerAppApi = createAsyncThunk(
  "customer/getCustomerAppApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CustomerService.customerAppSearchGrid(data);
      const orgData = response.data;
      if (orgData.customerAppList && orgData.customerAppList.length > 0) {
        orgData.customerAppList = orgData.customerAppList.map(item => {
          return {...item, Uid: uuidv4() }
        })
        return orgData;
      }
      return response.data;
      
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  customerList: [],
  customerDetail: {},
  customerAppData: {},
  totalCount: 0,
  totalOrderNumber: 0,
  totalTotalOrderAmount: 0,
  totalTotalOrderPayment: 0,
};
export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerApi.fulfilled, (state, action) => {
        state.loading = false;
        state.customerList = action.payload;
        state.totalCount = action.payload.totalCount;
        state.totalOrderNumber = action.payload.totalOrderNumber;
        state.totalTotalOrderAmount = action.payload.totalTotalOrderAmount;
        state.totalTotalOrderPayment = action.payload.totalTotalOrderPayment;
      })
      .addCase(getCustomerApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCustomerAppApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerAppApi.fulfilled, (state, action) => {
        state.loading = false;
        state.customerAppData = action.payload;
      })
      .addCase(getCustomerAppApi.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setCustomerDetail } = customerSlice.actions;

export default customerSlice.reducer;
