import reportService from "services/reportService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const reportRevenue = createAsyncThunk(
    "report/reportRevenue",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.reportRevenue();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const reportRevenueV2 = createAsyncThunk(
    "report/reportRevenueV2",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.reportRevenueV2(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const reportTimeV2 = createAsyncThunk(
    "report/reportTimeV2",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.reportTimeV2(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


export const reportTopProduct = createAsyncThunk(
    "report/reportTopProduct",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.reportTopProduct(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);
export const reportStatusOrder = createAsyncThunk(
    "report/reportStatusOrder",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.reportStatusOrder(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const reportTopEmployee = createAsyncThunk(
    "report/reportTopEmployee",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.reportTopEmployee(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const reportChartRevenue = createAsyncThunk(
    "report/reportChartRevenue",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.reportChartRevenue(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const reportAccountRevenue = createAsyncThunk(
    "report/reportAccountRevenue",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.reportAccountRevenue(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const reportAccountChartRevenue = createAsyncThunk(
    "report/reportAccountChartRevenue",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.reportAccountChartRevenue(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    reportRevenueList: [],
    reportTopProductList: [],
    reportStatusOrderList: [],
    reportTopEmployeeList: [],
    reportChartRevenueList: [],
    reportAccountRevenueList: [],
    reportAccountChartRevenueList: [],
    reportRevenueListV2: [],
    reportTimeListV2: [],
    reportViolation: [],
};

const reportSlice = createSlice(
    {
        name: "report",
        initialState,
        reducers: {
            showLoading: (state) => {
                state.loading = true;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(reportRevenue.pending, (state) => {
                    state.loading = true;
                })
                .addCase(reportRevenue.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportRevenueList = action.payload;
                })
                .addCase(reportRevenue.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(reportRevenueV2.pending, (state) => {
                    state.loading = true;
                })
                .addCase(reportRevenueV2.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportRevenueListV2 = action.payload;
                })
                .addCase(reportRevenueV2.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(reportTimeV2.pending, (state) => {
                    state.loading = true;
                })
                .addCase(reportTimeV2.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportTimeListV2 = action.payload;
                })
                .addCase(reportTimeV2.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(reportTopProduct.pending, (state) => {
                    state.loading = true;
                })
                .addCase(reportTopProduct.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportTopProductList = action.payload;
                })
                .addCase(reportTopProduct.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(reportStatusOrder.pending, (state) => {
                    state.loading = true;
                })
                .addCase(reportStatusOrder.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportStatusOrderList = action.payload;
                })
                .addCase(reportStatusOrder.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(reportTopEmployee.pending, (state) => {
                    state.loading = true;
                })
                .addCase(reportTopEmployee.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportTopEmployeeList = action.payload;
                })
                .addCase(reportTopEmployee.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(reportChartRevenue.pending, (state) => {
                    state.loading = true;
                })
                .addCase(reportChartRevenue.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportChartRevenueList = action.payload;
                })
                .addCase(reportChartRevenue.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(reportAccountRevenue.pending, (state) => {
                    state.loading = true;
                })
                .addCase(reportAccountRevenue.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportAccountRevenueList = action.payload;
                })
                .addCase(reportAccountRevenue.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(reportAccountChartRevenue.pending, (state) => {
                    state.loading = true;
                })
                .addCase(reportAccountChartRevenue.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportAccountChartRevenueList = action.payload;
                })
                .addCase(reportAccountChartRevenue.rejected, (state, action) => {
                    state.loading = false;
                });
        },
    }
);

export const { showLoading } = reportSlice.actions;

export default reportSlice.reducer;
