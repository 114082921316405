import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import openNotification from "../../utils/notification";
import Utils from "../../utils";
import wordViolationService from "../../services/wordViolationService";

export const getWordViolation = createAsyncThunk(
  "report-violation/getWordViolation",
  async (_, { rejectWithValue }) => {
    try {
      const response = await wordViolationService.getWordViolation();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addWordViolation = createAsyncThunk(
  "report-violation/addWordViolation",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await wordViolationService.addWordViolation(data);
      if(response.data.ok) {
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateWordViolation = createAsyncThunk(
  "report-violation/updateWordViolation",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await wordViolationService.updateWordViolation(data);
      if(response.data.ok) {
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const delWordViolation = createAsyncThunk(
  "report-violation/delWordViolation",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await wordViolationService.deleteWordViolation(id);
      if(response.data.ok) {
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
      }
    return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  dataList: [],
  dataSource: [],
};

const wordViolationSlice = createSlice(
  {
    name: 'wordViolation',
    initialState,
    reducers: {
      setWordData: (state, {payload}) => {
        state.dataList = payload;
      }
    },
    extraReducers: builder => {
      builder.addCase(getWordViolation.pending, state => {
        state.loading = true;
      });
      builder.addCase(getWordViolation.fulfilled, (state, action) => {
        state.loading = false;
        state.dataList = action.payload;
        state.dataSource  = action.payload
      });
      builder.addCase(getWordViolation.rejected, state => {
        state.loading = false;
      });
      builder.addCase(addWordViolation.pending, state => {
        state.loading = true;
      });
      builder.addCase(addWordViolation.fulfilled, state => {
        state.loading = false;
      });
      builder.addCase(addWordViolation.rejected, state => {
        state.loading = false;
      });
      builder.addCase(updateWordViolation.pending, state => {
        state.loading = true;
      });
      builder.addCase(updateWordViolation.fulfilled, state => {
        state.loading = false;
      });
      builder.addCase(updateWordViolation.rejected, state => {
        state.loading = false;
      });
      builder.addCase(delWordViolation.pending, state => {
        state.loading = true;
      });
      builder.addCase(delWordViolation.fulfilled, state => {
        state.loading = false;
      });
      builder.addCase(delWordViolation.rejected, state => {
        state.loading = false;
      });
    }
  }
);

export const {setWordData} = wordViolationSlice.actions;

export default wordViolationSlice.reducer;