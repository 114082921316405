import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Utils from "utils";
import openNotification from "utils/notification";
import cloneDeep from "lodash";
import StoreTableService from "services/StoreTablService";


export const getMonitorStoreDinnerTable = createAsyncThunk(
  "store/getMonitorStoreDinnerTable",
  async (data, { rejectWithValue }) => {
    try {
      const response = await StoreTableService.getMonitorStoreDinnerTable(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getStoreDinnerTable = createAsyncThunk(
  "store/getStoreDinnerTable",
  async (data, { rejectWithValue }) => {
    try {
      const response = await StoreTableService.getStoreDinnerTable(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const createStoreDinnerTableApi = createAsyncThunk(
  "store/createStoreDinnerTable",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await StoreTableService.createStoreDinnerTable(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getStoreDinnerTableQRApi = createAsyncThunk(
  "store/getStoreDinnerTableQRApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await StoreTableService.getStoreDinnerTableQR(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateStoreDinnerTableApi = createAsyncThunk(
  "store/updateStoreDinnerTable",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await StoreTableService.updateStoreDinnerTable(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateStoreDinnerTableLogPrintApi = createAsyncThunk(
  "store/updateStoreDinnerTableLogPrintApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await StoreTableService.updateStoreDinnerTableLogPrint(
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const delStoreDinnerTableApi = createAsyncThunk(
  "store/delStoreDinnerTableApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await StoreTableService.delStoreDinnerTable(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const createMultiStoreDinnerTableApi = createAsyncThunk(
  "store/createMultiStoreDinnerTableApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await StoreTableService.createMultiStoreDinnerTableApi(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  storeTableList: [],
  storeTableQRList: [],
  monitorStoreDinner: [],
  isFullScreen: false,
};
export const storeDinnerTableSlice = createSlice({
  name: "storeTable",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setStoreTable: (state, action) => {
      state.storeTableList = action.payload;
    },
    setFullScreen: (state, action) => {
      state.isFullScreen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoreDinnerTable.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStoreDinnerTable.fulfilled, (state, action) => {
        state.loading = false;
        state.storeTableList = action.payload;
      })
      .addCase(getStoreDinnerTable.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getStoreDinnerTableQRApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStoreDinnerTableQRApi.fulfilled, (state, action) => {
        state.loading = false;
        state.storeTableQRList = action.payload;
      })
      .addCase(getStoreDinnerTableQRApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createStoreDinnerTableApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(createStoreDinnerTableApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createStoreDinnerTableApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreDinnerTableApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStoreDinnerTableApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreDinnerTableApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreDinnerTableLogPrintApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStoreDinnerTableLogPrintApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreDinnerTableLogPrintApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(delStoreDinnerTableApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delStoreDinnerTableApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(delStoreDinnerTableApi.rejected, (state, action) => {
        state.loading = false;
      })
      // .addCase(getMonitorStoreDinnerTable.pending, (state) => {
      //   state.loading = true;
      // })
      .addCase(getMonitorStoreDinnerTable.fulfilled, (state, action) => {
        state.loading = false;
        state.monitorStoreDinner = action.payload;
      })
      .addCase(getMonitorStoreDinnerTable.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createMultiStoreDinnerTableApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(createMultiStoreDinnerTableApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createMultiStoreDinnerTableApi.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setStoreTable, setFullScreen } = storeDinnerTableSlice.actions;

export default storeDinnerTableSlice.reducer;
