import React from "react";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";
import {AUTHENTICATED_ADMIN, AUTHENTICATED_EMPLOYEE, AUTHENTICATED_ENTRY} from "configs/AppConfig";
import { protectedRoutes, publicRoutes } from "configs/RoutesConfig";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AppRoute from "./AppRoute";
import {SYSTEM_ROLES, USER_ROLES} from "../constants/AuthConstant";

const Routes = () => {
  const currentRole = localStorage ? parseInt(localStorage.getItem(USER_ROLES), 10) : 0;
  const getScreenNavigate = () => {
    if (currentRole === SYSTEM_ROLES.OperatorAdmin) {
        return AUTHENTICATED_ADMIN;
    }

    if ([SYSTEM_ROLES.StoreEmployee, SYSTEM_ROLES.KitchenEmployee].includes(currentRole)) {
        return AUTHENTICATED_EMPLOYEE;
    }

    return AUTHENTICATED_ENTRY;
  };

  return (
    <RouterRoutes>
      <Route path="/" element={<ProtectedRoute currentRole={currentRole} />}>
        <Route
          path="/"
          element={<Navigate replace to={getScreenNavigate()} />}
        />
        {protectedRoutes.map((route, index) => {
          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  title={route.title}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  title={route.title}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
