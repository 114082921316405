import React, { lazy, Suspense, memo } from 'react'
import { useSelector } from 'react-redux';
import Loading from 'components/shared-components/Loading';
import Views from 'views';
import {tokenAuthSelector} from "../store/selector/AuthSelector";
import {blankLayoutSelector} from "../store/selector/ThemeSelector";

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {
	const token = useSelector(tokenAuthSelector);
	const blankLayout = useSelector(blankLayoutSelector);

	const Layout = token && !blankLayout ? AppLayout : AuthLayout
	return (
		<Suspense fallback={<Loading cover="content"/>}>
			<Layout>
				<Views />
			</Layout>
		</Suspense>
	)
}


export default memo(Layouts)
