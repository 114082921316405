import axiosInstance from "../auth/FetchInterceptor";
import {SETTING_WORD_VIOLATION} from "constants/ApiConstant";

const wordViolationService = {};

wordViolationService.getWordViolation = () => {
  return axiosInstance.get(SETTING_WORD_VIOLATION, null);
};

wordViolationService.addWordViolation = (data) => {
  return axiosInstance.post(SETTING_WORD_VIOLATION, data);
};

wordViolationService.updateWordViolation = (data) => {
  return axiosInstance.put(SETTING_WORD_VIOLATION, data);
};

wordViolationService.deleteWordViolation = (id) => {
  return axiosInstance.delete(`${SETTING_WORD_VIOLATION}/${id}`, null);
};

export default wordViolationService;