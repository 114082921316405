import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import settingStoreCate from "./slices/settingStoreCateSlice";
import settingMenuCate from "./slices/settingMenuCateSlice";
import settingSystem from './slices/settingSystemSlice';
import partner from "./slices/partnerSlice";
import location from "./slices/locationSlice";
import settingStoreUtility from "./slices/settingStoreUtilitySlice";
import storeApi from "./slices/storeSlice";
import attributeCate from "./slices/settingProductAttribute";
import product from "./slices/ProductSlice";
import storeWifi from "./slices/storeWifiSlice";
import storeTable from "./slices/storeDinnerTableSlice";
import settingBanner from "./slices/settingBannerSlice";
import customer from "./slices/CustomerSlice";
import employee from "./slices/EmployeeSlice";
import orderApi from "./slices/orderSlice";
import report from "./slices/reportSlice";
import notification from "./slices/NotificationSlice";
import post from "./slices/postSlice";
import shoppingCart from "./slices/shoppingCartSlice";
import postComment from "./slices/postCommentSlice";
import sInvoiceSlice from "./slices/SInvoiceSlice";
import saasSlice from "./slices/saasSlice";
import settingGroupCateSlice from "./slices/settingGroupCateSlice";
import settingRatingCate from "./slices/settingRatingCateSlice";
import reportViolation from "./slices/reportViolationSlice";
import wordViolation from "./slices/wordViolationSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    storeApi,
    settingStoreCate,
    settingMenuCate,
    settingSystem,
    settingStoreUtility,
    partner,
    location,
    attributeCate,
    product,
    storeWifi,
    storeTable,
    settingBanner,
    customer,
    employee,    orderApi,
    report,
    notification,
    post,
    shoppingCart,
    postComment,
    sInvoiceSlice,
    saasSlice,
    settingGroupCateSlice,
    settingRatingCate,
    reportViolation,
    wordViolation,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
