import React, { useEffect } from "react";
import { onBlankLayout } from "store/slices/themeSlice";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

const AppRoute = ({
  component: Component,
  title,
  routeKey,
  blankLayout,
  ...props
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const isBlank = blankLayout ? true : false;
    dispatch(onBlankLayout(isBlank));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blankLayout]);

  return (
    <>
      <Helmet title={title} />
      <Component {...props} />
    </>
  );
};

export default AppRoute;
