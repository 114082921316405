import {createSelector} from 'reselect';

export const blankLayoutSelector = createSelector(
    (state) => state.theme,
    app => app.blankLayout,
);

export const localeSelector = createSelector(
    (state) => state.theme,
    app => app.locale,
);

export const navSelector = createSelector(
    (state) => state.theme,
    app => app.navCollapsed,
);