import fetch from "auth/FetchInterceptor";
import { CLONE_POST, COUNT_TOTAL_VIEW_POST, DELETE_POST, POST_API, SEARCH_POST } from "constants/ApiConstant";

const PostService = {}

PostService.postSearchGrid = function (data) {
  return fetch.post(SEARCH_POST, data, {
      baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
    });
};
PostService.getPostById = function (id) {
    return fetch.post(`${POST_API}/${id}`, null, {
      baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
    });
};
PostService.addPostApi = function (data) {
    return fetch.post(POST_API, data, {
        baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
      });
};
PostService.updatePostApi = function (data) {
    return fetch.put(POST_API, data, {
        baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
      });
};
PostService.delPostApi = function (id) {
    return fetch.post(`${DELETE_POST}/${id}`, null, {
        baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
      });
};
PostService.clonePostApi = function (id) {
  return fetch.post(`${CLONE_POST}/${id}`, null, {
      baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
    });
};
PostService.countTotalViewPost = function (id) {
  return fetch.post(`${COUNT_TOTAL_VIEW_POST}/${id}`, null, {
      baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
    });
};

export default PostService;