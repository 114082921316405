import axiosInstance from "../auth/FetchInterceptor";
import {
    REPORT_ACCOUNT_CHART_REVENUE, REPORT_ACCOUNT_REVENUE, REPORT_CHART_REVENUE, REPORT_REVENUE,
    REPORT_STATUS_ORDER, REPORT_TOP_EMPLOYEE, REPORT_TOP_PRODUCT, REPORT_REVENUE_V2, REPORT_TIME_V2,
} from "constants/ApiConstant";

const ReportService = {}

ReportService.reportRevenue = function () {
    return axiosInstance.get(REPORT_REVENUE, null);
};
ReportService.reportTopProduct = function (data) {
    return axiosInstance.post(REPORT_TOP_PRODUCT, data);
};
ReportService.reportStatusOrder = function (data) {
    return axiosInstance.post(REPORT_STATUS_ORDER, data);
};
ReportService.reportTopEmployee = function (data) {
    return axiosInstance.post(REPORT_TOP_EMPLOYEE, data);
};
ReportService.reportChartRevenue = function (data) {
    return axiosInstance.post(REPORT_CHART_REVENUE, data);
};
ReportService.reportAccountRevenue = function (data) {
    return axiosInstance.post(REPORT_ACCOUNT_REVENUE, data);
};
ReportService.reportAccountChartRevenue = function (data) {
    return axiosInstance.post(REPORT_ACCOUNT_CHART_REVENUE, data);
};
ReportService.reportRevenueV2 = function (data) {
    return axiosInstance.post(REPORT_REVENUE_V2, data);
};
ReportService.reportTimeV2 = function (data) {
    return axiosInstance.post(REPORT_TIME_V2, data);
};

export default ReportService;
