import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SettingService from "services/SettingService";

export const getProductAttributeCate = createAsyncThunk(
  "setting/getProductAttributeCate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.getProductAttribute();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  attributeList: [],
};
export const SettingProductAttribute = createSlice({
  name: "attributeCate",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductAttributeCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductAttributeCate.fulfilled, (state, action) => {
        state.loading = false;
        state.attributeList = action.payload;
      })
      .addCase(getProductAttributeCate.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = SettingProductAttribute.actions;

export default SettingProductAttribute.reducer;
