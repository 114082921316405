import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import ProductService from "services/ProductService";
import Utils from "utils";
import openNotification from "utils/notification";

export const getProductApi = createAsyncThunk(
  "product/getProductApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductService.productSearchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getProductMobileById = createAsyncThunk(
    "product/getProductMobileById",
    async (id, { rejectWithValue }) => {
      try {
        const response = await ProductService.getProductMobileById(id);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
);

export const getProductById = createAsyncThunk(
  "product/getProductById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProductService.getProductById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addProductApi = createAsyncThunk(
  "product/addProductApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductService.addProduct(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateProductApi = createAsyncThunk(
  "product/updateProductApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductService.updateProduct(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateProductStateApi = createAsyncThunk(
  "product/updateProductStateApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductService.updateProductState(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const delProductApi = createAsyncThunk(
  "product/delProductApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await ProductService.delProduct(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const copyProductsApi = createAsyncThunk(
  "product/copyProductsApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductService.copyProducts(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const groupCategoryApi = createAsyncThunk(
  "product/distributeProductsApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductService.groupCategory(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const changeStatusProductsApi = createAsyncThunk(
  "product/changeStatusProductsApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductService.changeStatusProducts(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const discountProductsApi = createAsyncThunk(
  "product/discountProductsApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await ProductService.discountProducts(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  productList: [],
  productDetail: {},
  productMobileDetail: {},
  totalCount: 0,
};
export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setProductDetail: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductApi.fulfilled, (state, action) => {
        state.loading = false;
        state.productList = action.payload.data;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(getProductApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getProductById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.loading = false;
        state.productDetail = action.payload;
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getProductMobileById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductMobileById.fulfilled, (state, action) => {
        state.loading = false;
        state.productMobileDetail = action.payload;
      })
      .addCase(getProductMobileById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProductApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addProductApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProductApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProductApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProductStateApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProductStateApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProductStateApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(delProductApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delProductApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(delProductApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(copyProductsApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(copyProductsApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(copyProductsApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(groupCategoryApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(groupCategoryApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(groupCategoryApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(changeStatusProductsApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(changeStatusProductsApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(changeStatusProductsApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(discountProductsApi.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(discountProductsApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(discountProductsApi.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setProductDetail } = productSlice.actions;

export default productSlice.reducer;
