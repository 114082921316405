import PostService from "services/postService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import openNotification from "utils/notification";
import Utils from "utils";

export const getPostApi = createAsyncThunk(
    "post/postSearchGrid",
    async (data, { rejectWithValue }) => {
      try {
        const response = await PostService.postSearchGrid(data);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
  );
  
  export const getPostById = createAsyncThunk(
    "post/getPostById",
    async (id, { rejectWithValue }) => {
      try {
        const response = await PostService.getPostById(id);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
  );

  export const addPostApi = createAsyncThunk(
    "post/addPostApi",
    async (data, { rejectWithValue }) => {
      const { onSuccess } = data;
      try {
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await PostService.addPostApi(payload);
        if (onSuccess) onSuccess(response);
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
  );
  
  export const updatePostApi = createAsyncThunk(
    "post/updatePostApi",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await PostService.updatePostApi(data);
        if (onSuccess) onSuccess(response);
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
  );
  
  export const delPostApi = createAsyncThunk(
    "post/delPostApi",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess, id } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await PostService.delPostApi(id);
        if (onSuccess) onSuccess(response);
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
  
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
  );
  

  export const clonePostApi = createAsyncThunk(
    "post/clonePostApi",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess, id } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await PostService.clonePostApi(id);
        if (onSuccess) onSuccess(response);
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
  
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
  );

  export const countTotalViewPost = createAsyncThunk(
    "post/countTotalViewPost",
    async (id, { rejectWithValue }) => {
      try {
        const response = await PostService.countTotalViewPost(id);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
  );


const initialState = {
    loading: false,
    postList: [],
    postDetail: {},
    totalCount: 0,
};

const postSlice = createSlice(
    {
        name: "post",
        initialState,
        reducers: {
            showLoading: (state) => {
                state.loading = true;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(getPostApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getPostApi.fulfilled, (state, action) => {
                    state.loading = false;
                    state.postList = action.payload;
                    state.totalCount = action.payload.totalCount;
                })
                .addCase(getPostApi.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getPostById.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getPostById.fulfilled, (state, action) => {
                    state.loading = false;
                    state.postDetail = action.payload;
                })
                .addCase(getPostById.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(addPostApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(addPostApi.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(addPostApi.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(updatePostApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(updatePostApi.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(updatePostApi.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(delPostApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(delPostApi.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(delPostApi.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(clonePostApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(clonePostApi.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(clonePostApi.rejected, (state, action) => {
                    state.loading = false;
                })
              .addCase(countTotalViewPost.pending, (state) => {
                state.loading = true;
              })
              .addCase(countTotalViewPost.fulfilled, (state, action) => {
                state.loading = false;
              })
              .addCase(countTotalViewPost.rejected, (state, action) => {
                state.loading = false;
              });
    },
    }
);

export const { showLoading } = postSlice.actions;

export default postSlice.reducer;