import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Utils from "utils";
import openNotification from "utils/notification";
import cloneDeep from "lodash";
import StoreWifiService from "services/StoreWifiService";

export const getStoreWifiApi = createAsyncThunk(
  "store/getStoreWifiApi",
  async (id, { rejectWithValue }) => {
    try {
      const response = await StoreWifiService.getStoreWifi(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const createStoreWifiApi = createAsyncThunk(
  "store/createStoreWifiApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await StoreWifiService.createStoreWifi(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateStoreWifiApi = createAsyncThunk(
  "store/updateStoreWifiApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await StoreWifiService.updateStoreWifi(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const delStoreWifiApi = createAsyncThunk(
  "store/delStoreWifiApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await StoreWifiService.delStoreWifi(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  storeWifiList: [],
};
export const storeWifiSlice = createSlice({
  name: "storeWifi",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setStoreWifi: (state, action) => {
      state.storeWifiList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoreWifiApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStoreWifiApi.fulfilled, (state, action) => {
        state.loading = false;
        state.storeWifiList = action.payload;
      })
      .addCase(getStoreWifiApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createStoreWifiApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(createStoreWifiApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createStoreWifiApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreWifiApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStoreWifiApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreWifiApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(delStoreWifiApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delStoreWifiApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(delStoreWifiApi.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setStoreWifi } = storeWifiSlice.actions;

export default storeWifiSlice.reducer;
