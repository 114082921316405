import axios from "axios";
// import { API_BASE_URL, IDENTITY_ENDPOINT_URL } from 'configs/AppConfig';
import {ACCOUNT_INVOICE, AUTH_TOKEN_INVOICE} from "constants/AuthConstant";
import {LOGIN_INVOICE} from "constants/ApiConstant";
import { notification } from "antd";

const service = axios.create({
  baseURL: process.env.REACT_APP_SINVOICE_URL,
  timeout: 60000,
});
const TOKEN_PAYLOAD_KEY = "authorization";
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN_INVOICE) || null;
    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }
    return config;
  },
  (error) => {
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    let notificationParam = {
      message: "",
    };
    if (error.response) {
      if ([401, 500].includes(error.response.status)) {
        try {
          const loginAccount = localStorage.getItem(ACCOUNT_INVOICE);
          if (loginAccount) {
            const response = await axios.post(
              LOGIN_INVOICE,
              JSON.parse(loginAccount),
              {baseURL: process.env.REACT_APP_SINVOICE_URL},
            );

            if (response.data) {
              localStorage.setItem(AUTH_TOKEN_INVOICE, response.data.access_token);
              const originalConfig = error.config;
              originalConfig._retry = true;
              service.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
              return await service(originalConfig);
            }
          }
        } catch (ex) {
          console.log(error);
        }
      }

      notificationParam.message = error.response.data?.data;
    } else {
      notificationParam.message = "Lỗi kết nối mạng";
    }

    if (!notificationParam.message) {
      notificationParam.message = "Vui lòng kiểm tra lại thông tin kết nối";
    }
    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
