import axiosInstance from "../auth/FetchInterceptor";
import {HANDLE_REPORT_VIOLATION, REPORT_VIOLATION, REPORT_VIOLATION_STATUS} from "constants/ApiConstant";

const reportViolationService = {};

reportViolationService.reportViolation = (data) => {
  return axiosInstance.post(REPORT_VIOLATION, data);
};

reportViolationService.changeStatusReport = (data) => {
  return axiosInstance.put(REPORT_VIOLATION_STATUS, data);
};

reportViolationService.handleReportViolation = (data) => {
  return axiosInstance.put(HANDLE_REPORT_VIOLATION, data);
};

export default reportViolationService;