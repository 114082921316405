export const AUTH_TOKEN = 'auth_token';
export const REFRESH_TOKEN = 'refresh_token';
export const EXPIRE_TIME = 'expire_time';
export const FIREBASE_TOKEN = 'firebase_token';
export const LANGUAGE_LOCAL = 'language_local';
export const USER_ROLES = 'user_roles';
export const MENU_SORT = 'menu_sort';
export const MENU_TYPE = 'menu_type';
export const AUTH_TOKEN_INVOICE = 'auth_token_invoice';
export const ACCOUNT_INVOICE = 'account_invoice';

export const SYSTEM_ROLES = {
    None: 0,
    // Operation system 0x
    OperatorAdmin: 1,
    // Partner role 1X
    PartnerAdmin: 10,
    StoreAdmin: 11,
    StoreEmployee: 12,
    KitchenEmployee: 13,
    AccountantEmployee: 14,
    // EndUser 2X
    EndUser: 20,
}