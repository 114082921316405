
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shoppingCartService from "services/shoppingCartService";
import {cloneDeep} from "lodash";


export const getGetTableCart = createAsyncThunk(
    "tableCartDetail/gettableCartDetail",
    async (id, { rejectWithValue }) => {
        try {
            const response = await shoppingCartService.getGetTableCart(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const upsetTableCart = createAsyncThunk(
    "tableCartDetail/upsetTableCart",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await shoppingCartService.upsetTableCart(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const upsetTableCartExternal = createAsyncThunk(
    "tableCartDetail/upsetTableCartExternal",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await shoppingCartService.upsetTableCartExternal(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const upsetShoppingCart = createAsyncThunk(
    "tableCartDetail/upsetShoppingCart",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await shoppingCartService.upsetShoppingCart(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const deleteShoppingCart = createAsyncThunk(
    "tableCartDetail/deleteShoppingCart",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await shoppingCartService.deleteShoppingCart(payload.id);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const changeCartItemQuantity = createAsyncThunk(
    "tableCartDetail/changeCartItemQuantity",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await shoppingCartService.changeCartItemQuantity(payload.data);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const changeCustomerCart = createAsyncThunk(
    "tableCartDetail/changeCustomerCart",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await shoppingCartService.changeCustomerCart(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const changeTableCart = createAsyncThunk(
    "tableCartDetail/changeTableCart",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await shoppingCartService.changeTableCart(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const sortCartItems = createAsyncThunk(
    "tableCartDetail/sortCartItems",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await shoppingCartService.sortCartItems(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    tableCartDetail: {},
};

export const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setProductDetail: (state) => {
            state.loading = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGetTableCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(getGetTableCart.fulfilled, (state, action) => {
                state.loading = false;
                state.tableCartDetail = action.payload;
            })
            .addCase(getGetTableCart.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(upsetTableCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(upsetTableCart.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(upsetTableCart.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(upsetTableCartExternal.pending, (state) => {
                state.loading = true;
            })
            .addCase(upsetTableCartExternal.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(upsetTableCartExternal.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(upsetShoppingCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(upsetShoppingCart.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(upsetShoppingCart.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteShoppingCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteShoppingCart.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteShoppingCart.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(changeCartItemQuantity.pending, (state) => {
                state.loading = true;
            })
            .addCase(changeCartItemQuantity.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(changeCartItemQuantity.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(changeCustomerCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(changeCustomerCart.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(changeCustomerCart.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(changeTableCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(changeTableCart.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(changeTableCart.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const { showLoading, setProductDetail } = productSlice.actions;

export default productSlice.reducer;
