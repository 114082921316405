import axios from "axios";
// import { API_BASE_URL, IDENTITY_ENDPOINT_URL } from 'configs/AppConfig';
import { signOutSuccess } from "store/slices/authSlice";
import store from "../store";
import { AUTH_TOKEN, REFRESH_TOKEN } from "constants/AuthConstant";
import { REFRESH_TOKEN_API } from "constants/ApiConstant";
import { notification } from "antd";
import { setAuthenData } from "utils/helper";

const unauthorizedCode = [401, 403];

const service = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_URL,
  timeout: 60000,
});
// Config
const TOKEN_PAYLOAD_KEY = "authorization";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }
    return config;
  },
  (error) => {
    console.log("config error", error);
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    console.log("error res", error.response);
    let notificationParam = {
      message: "",
    };
    if (error.response) {
      // Remove token and redirect
      if (unauthorizedCode.includes(error.response.status)) {
        notificationParam.message = "Lỗi xác thực";
        notificationParam.description = "Bạn không có quyền thao tác";
        // localStorage.removeItem(AUTH_TOKEN);
        // store.dispatch(signOutSuccess());
      }
      if (error.response.status === 601) {
        // token expired
        try {
          const refreshToken = localStorage.getItem(REFRESH_TOKEN);
          if (refreshToken) {
            const response = await axios.post(
              REFRESH_TOKEN_API,
              {
                Token: refreshToken,
              },
              { baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL }
            );
            console.log("refresh response", response);
            if (response.data && response.data.data) {
              setAuthenData(response.data.data);
              const originalConfig = error.config;
              originalConfig._retry = true;
              service.defaults.headers.common.Authorization = `Bearer ${response.data.data.tokenData.token}`;
              return await service(originalConfig);
            }
          }
        } catch (_error) {
          notificationParam.message = "Phiên đăng nhập hết hạn";
          notificationParam.description = "Xin vui lòng đăng nhập lại";
          store.dispatch(signOutSuccess());
        }
      }
      if (error.response.status === 600) {
        notificationParam.message = `Lỗi (${error.response.data.errorCode}): ${error.response.data.description}`;
      }
      if (error.response.status === 404) {
        notificationParam.message = "Not Found";
      }

      if (error.response.status === 500) {
        notificationParam.message = "Internal Server Error";
      }

      if (error.response.status === 508) {
        notificationParam.message = "Time Out";
      }

      if (error.response.status === 400) {
        notificationParam.message = `Lỗi (${error.response.data.status}): ${error.response.data.title}`;
      }
    } else {
      notificationParam.message = "Lỗi kết nối mạng";
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
