import axiosInstance from "auth/FetchInterceptor";
import fetch from "auth/FetchInterceptor";
import { ADD_POST_COMMENT, DEL_POST_COMMENT, GET_REPLY_POST_COMMENT, LIKE_POST_COMMENT, REPLY_POST_COMMENT, SEARCH_POST_COMMENT, TOGGLE_HIDE_POST_COMMENT, UPDATE_POST_COMMENT } from "constants/ApiConstant";
const PostCommentService = {};

PostCommentService.postCommentSearchGrid = function (data) {
  console.log("data",data);
  return fetch.post(SEARCH_POST_COMMENT, data, {
      baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
    });
};
PostCommentService.addPostCommentApi = function (data) {
  return fetch.post(ADD_POST_COMMENT, data, {
      baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
    });
};

PostCommentService.updatePostCommentApi = function (data) {
  return fetch.put(UPDATE_POST_COMMENT, data, {
      baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
    });
};

PostCommentService.likeCommentApi = function (postCommentId) {
  return fetch.post(`${LIKE_POST_COMMENT}/${postCommentId}`, null, {
      baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
    });
};

PostCommentService.delCommentApi = function (postCommentId) {
  return fetch.post(`${DEL_POST_COMMENT}/${postCommentId}`, null, {
      baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
    });
};

PostCommentService.replyCommentApi = function (data) {
  return fetch.post(REPLY_POST_COMMENT, data, {
      baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
    });
};

PostCommentService.getRepliesForComment = function (data) {
  return fetch.post(GET_REPLY_POST_COMMENT, data, {
      baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
    });
};

PostCommentService.toggleHideCommentApi = function (postCommentId) {
  return fetch.post(`${TOGGLE_HIDE_POST_COMMENT}/${postCommentId}`, null, {
      baseURL: process.env.REACT_APP_SOCIAL_ENDPOINT_URL,
    });
};

export default PostCommentService;
