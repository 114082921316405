import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import reportViolationService from "../../services/reportViolationService";
import openNotification from "../../utils/notification";
import Utils from "../../utils";

export const reportViolation = createAsyncThunk(
  "report-violation/reportViolation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await reportViolationService.reportViolation(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const changeStatusReportViolation = createAsyncThunk(
  "report-violation/changeStatusReportViolation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await reportViolationService.changeStatusReport(data);
      openNotification(
        'success',
        'Cập nhật trạng thái báo cáo thành công',
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const handleReportViolation = createAsyncThunk(
  "report-violation/handleReportViolation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await reportViolationService.handleReportViolation(data);
      openNotification(
        'success',
        'Xử lý vi phạm thành công',
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  dataList: [],
  totalCount: 0,
};

const reportViolationSlice = createSlice(
  {
    name: 'violation',
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder.addCase(reportViolation.pending, state => {
        state.loading = true;
      });
      builder.addCase(reportViolation.fulfilled, (state, action) => {
        state.loading = false;
        state.dataList = action.payload.data;
        state.totalCount = action.payload.totalCount;
      });
      builder.addCase(reportViolation.rejected, state => {
        state.loading = false;
      });
      builder.addCase(changeStatusReportViolation.pending, state => {
        state.loading = true;
      });
      builder.addCase(changeStatusReportViolation.fulfilled, state => {
        state.loading = false;
      });
      builder.addCase(changeStatusReportViolation.rejected, state => {
        state.loading = false;
      });
      builder.addCase(handleReportViolation.pending, state => {
        state.loading = true;
      });
      builder.addCase(handleReportViolation.fulfilled, state => {
        state.loading = false;
      });
      builder.addCase(handleReportViolation.rejected, state => {
        state.loading = false;
      });
    }
  }
);

export const {} = reportViolationSlice.actions;

export default reportViolationSlice.reducer;