import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SettingService from "services/SettingService";
import openNotification from "../../utils/notification";
import Utils from "../../utils";

export const getAllRatingCate = createAsyncThunk(
  "setting/getAllRatingCate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetAllRatingCate();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getSettingRate = createAsyncThunk(
  "setting/getSettingRate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetSettingRate();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateSettingRate = createAsyncThunk(
  "setting/updateSettingRate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.UpdateSettingRate(data);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);


export const addRatingCate = createAsyncThunk(
  "setting/addRatingCate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.AddRatingCate(data);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateRatingCate = createAsyncThunk(
  "setting/updateRatingCate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.UpdateRatingCate(data);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteRatingCate = createAsyncThunk(
  "setting/deleteRatingCate",
  async (id, { rejectWithValue }) => {
    try {
      const response = await SettingService.DeleteRatingCate(id);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  dataList: [],
  dataSource: [],
  settingRate: {},
};
export const settingRatingCateSlice = createSlice({
  name: "settingRatingCate",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setDataList: (state, {payload}) => {
      state.dataList = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRatingCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRatingCate.fulfilled, (state, action) => {
        state.loading = false;
        state.dataList = action.payload;
        state.dataSource = action.payload;
      })
      .addCase(getAllRatingCate.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSettingRate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSettingRate.fulfilled, (state, action) => {
        state.loading = false;
        state.settingRate = action.payload;
      })
      .addCase(getSettingRate.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setDataList } = settingRatingCateSlice.actions;

export default settingRatingCateSlice.reducer;
