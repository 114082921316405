import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import SInvoiceService from "services/SInvoiceService";
import openNotification from "../../utils/notification";
import Utils from "../../utils";
import {ACCOUNT_INVOICE, AUTH_TOKEN_INVOICE} from "../../constants/AuthConstant";
import {cloneDeep} from "lodash";
import {HSM, SIGN_SERVER} from "../../views/app-views/admin/settings/electronic-invoice/invoidConstant";

const initialState = {
  isLoading: false,
  templateList: [],
  storeInvoice: null,
  orderInvoice: null,
}

export const loginSInvoice = createAsyncThunk(
  "invoice/login",
  async (data, {rejectWithValue}) => {
    try {
      const {onSuccess} = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SInvoiceService.loginSInvoice(payload);
      if (response.access_token) {
        localStorage.setItem(ACCOUNT_INVOICE, JSON.stringify(payload));
        localStorage.setItem(AUTH_TOKEN_INVOICE, response.access_token);
      }

      if (onSuccess) onSuccess(response);
      return response;
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const getAllTemplate = createAsyncThunk(
  "invoice/getAllTemplate",
  async (taxCode, {rejectWithValue}) => {
    try {
      const response = await SInvoiceService.getAllInvoiceTemplates(taxCode);
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
      return response;
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const upSetInvoice = createAsyncThunk(
  "invoice/upSetInvoice",
  async (data, {rejectWithValue}) => {
    try {
      const response = await SInvoiceService.upsetStoreInvoice(data);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response;
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const upSetOrderInvoice = createAsyncThunk(
  "invoice/upSetOrderInvoice",
  async (data, {rejectWithValue}) => {
    try {
      const {onSuccess} = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SInvoiceService.upsetOrderInvoice(payload);
      const messageNoti = payload.cancelRequest ? "cancelRequest" : "sendRequest";
      openNotification(
        "success",
        Utils.setLocale(`admin.settings.popup.notification.${messageNoti}`),
        Utils.setLocale("admin.settings.popup.notification.title")
      );

      if (onSuccess) onSuccess(response);
      return response;
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const getStoreInvoice = createAsyncThunk(
  "invoice/getStoreInvoice",
  async (storeId, {rejectWithValue}) => {
    try {
      const response = await SInvoiceService.getStoreInvoice(storeId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const getOrderInvoice = createAsyncThunk(
  "invoice/getOrderInvoice",
  async (orderId, {rejectWithValue}) => {
    try {
      const response = await SInvoiceService.getOrderInvoice(orderId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const getCustomerInvoice = createAsyncThunk(
  "invoice/getCustomerInvoice",
  async (userId, {rejectWithValue}) => {
    try {
      const response = await SInvoiceService.getCustomerInvoice(userId);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const generateOrderInvoice = createAsyncThunk(
  "invoice/generateOrderInvoice",
  async (data, {rejectWithValue, dispatch}) => {
    try {
      const {onSuccess, orderIds} = data;
      let timeOuts = null;
      const response = await SInvoiceService.generateInvoice(orderIds);
      if (response && response.data) {
        const {username, password, signature, templateCode, authTypeInvoice, invoiceList} = response.data;
        const newAccount = JSON.stringify({username, password});
        const localAccount = localStorage.getItem(ACCOUNT_INVOICE);
        if (newAccount !== localAccount) {
          localStorage.removeItem(AUTH_TOKEN_INVOICE);
          localStorage.setItem(ACCOUNT_INVOICE, newAccount);
        }

        if (Array.isArray(invoiceList) && invoiceList.length > 0) {
          dispatch(showLoading(true));
          timeOuts = invoiceList.map((invoice, idx)=>
            setTimeout(async () => {
              if ([HSM, SIGN_SERVER].includes(authTypeInvoice)) {
                SInvoiceService.createHSMInvoice(invoice).then(async invoiceResult => {
                  await SInvoiceService.signInvoice({...invoiceResult.result, orderId: orderIds[idx]});
                  openNotification(
                    "success",
                    Utils.setLocale("admin.settings.popup.notification.createdInvoice"),
                    Utils.setLocale("admin.settings.popup.notification.title")
                  );
                }).finally(() => {
                  if (idx === invoiceList.length - 1) {
                    dispatch(showLoading(false));
                    if (onSuccess) onSuccess();
                  }
                });
              } else {
                const hashUSB = await SInvoiceService.createUSBHash(invoice).catch(() => {
                  dispatch(showLoading(false));
                });
                if (hashUSB && hashUSB.result) {
                  const payload = {
                    hashString: hashUSB.result.hashString,
                    supplierTaxCode: username,
                    templateCode: templateCode,
                    signature: signature,
                  };
                  SInvoiceService.signatureUSBInvoice(payload).then(async invoiceResult => {
                    await SInvoiceService.signInvoice({...invoiceResult.result, orderId: orderIds[idx]});
                    openNotification(
                      "success",
                      Utils.setLocale("admin.settings.popup.notification.createdInvoice"),
                      Utils.setLocale("admin.settings.popup.notification.title")
                    );
                  }).finally(() => {
                    if (idx === invoiceList.length - 1) {
                      dispatch(showLoading(false));
                      if (onSuccess) onSuccess();
                    }
                  });
                }
              }
            }, idx * 1500));
        } else {
          dispatch(showLoading(false));
          openNotification(
            "warning",
            'Vui lòng không chọn đơn đã bị huỷ!',
            Utils.setLocale("admin.settings.popup.notification.title")
          );
          return rejectWithValue("Vui lòng không xuất đơn đã huỷ!");
        }
      }

      return {...response.data, timeOuts};
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const createUSBTokenHash = createAsyncThunk(
  "invoice/createUSBTokenHash",
  async (data, {rejectWithValue}) => {
    try {
      const payload = cloneDeep(data);
      delete payload.orderId;
      return await SInvoiceService.createUSBHash(payload);
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const createPDFInvoice = createAsyncThunk(
  "invoice/createPDFInvoice",
  async (data, {rejectWithValue}) => {
    try {
      return await SInvoiceService.createPdfInvoice(data);
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const getInvoiceUuid = createAsyncThunk(
  "invoice/getInvoiceUuid",
  async (data, {rejectWithValue}) => {
    try {
      const response = await SInvoiceService.getInvoiceUuid(data);
      return response;
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const getInvoiceCertificate = createAsyncThunk(
  "invoice/getInvoiceCertificate",
  async (data, {rejectWithValue}) => {
    try {
      const response = await SInvoiceService.getCertificateInfo(data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const getBusinessVietQR = createAsyncThunk(
  "invoice/getBusinessVietQR",
  async (taxCode, {rejectWithValue}) => {
    try {
      const response = await SInvoiceService.searchBusiness(taxCode);
      const typeNoti = response && response.data ? "success" : "warning";
      openNotification(typeNoti, response?.desc, Utils.setLocale("admin.settings.popup.notification.title"));
      return response.data;
    } catch (e) {
      return rejectWithValue(e.message || "Error");
    }
  }
);

export const sInvoiceSlice = createSlice({
  name: 'sInvoice',
  initialState,
  reducers: {
    showLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers : (builder) => {
    builder
      .addCase(getAllTemplate.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllTemplate.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAllTemplate.fulfilled, (state, action) => {
        state.templateList = action.payload.template;
        state.isLoading = false;
      })
      .addCase(getStoreInvoice.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStoreInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.storeInvoice = action.payload;
      })
      .addCase(getStoreInvoice.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getOrderInvoice.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getOrderInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orderInvoice = action.payload;
      })
      .addCase(getOrderInvoice.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(upSetInvoice.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(upSetInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(upSetInvoice.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(upSetOrderInvoice.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(upSetOrderInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(upSetOrderInvoice.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getInvoiceUuid.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getInvoiceUuid.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getInvoiceUuid.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getBusinessVietQR.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBusinessVietQR.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getBusinessVietQR.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(generateOrderInvoice.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(generateOrderInvoice.rejected, (state, action) => {
        state.isLoading = false;
      });
    }
});

export const {showLoading} = sInvoiceSlice.actions;

export default sInvoiceSlice.reducer;
