import axiosInstance from "auth/FetchInterceptor";
import {
  CUSTOMER_APP_SEARCH_GRID,
  CUSTOMER_SEARCH_GRID,
} from "constants/ApiConstant";
const CustomerService = {};

CustomerService.customerSearchGrid = function (data) {
  return axiosInstance.post(CUSTOMER_SEARCH_GRID, data);
};

CustomerService.customerAppSearchGrid = function (data) {
  return axiosInstance.post(CUSTOMER_APP_SEARCH_GRID, data);
};


export default CustomerService;
