import axiosInstance from "../auth/FetchInterceptor";
import { DEL_ALL_NOTIFICATION, DEL_MULTI_NOTIFICATION, READ_ALL_NOTIFICATION, READ_NOTIFICATION, SAVE_FIREBASE_TOKEN_NOTIFICATION, SAVE_LABEL_STAR_NOTIFICATION, SEARCH_NOTIFICATION } from "constants/ApiConstant";

const NotificationService = {}

NotificationService.getNotificationApi = function (data) {
    return axiosInstance.post(SEARCH_NOTIFICATION, data);
};
NotificationService.readAllApi = function () {
    return axiosInstance.get(READ_ALL_NOTIFICATION, null);
};

NotificationService.delAllApi = function () {
    return axiosInstance.get(DEL_ALL_NOTIFICATION, null);
};
NotificationService.saveFirebaseTokenApi = function (data) {
    return axiosInstance.post(SAVE_FIREBASE_TOKEN_NOTIFICATION, data);
};
NotificationService.readNotificationApi = function (notiId) {
    return axiosInstance.get(`${READ_NOTIFICATION}/${notiId}`, null);
};
NotificationService.saveLabelStarApi = function (data) {
    return axiosInstance.post(SAVE_LABEL_STAR_NOTIFICATION, data);
};
NotificationService.delMultiNotiApi = function (data) {
    return axiosInstance.post(DEL_MULTI_NOTIFICATION, data);
};

export default NotificationService;