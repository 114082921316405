import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SettingService from "services/SettingService";

export const getTermCondition = createAsyncThunk(
  "setting/getTermCondition",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetTermCondition();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
export const getPrivacy = createAsyncThunk(
  "setting/getPrivacy",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetPrivacy();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  termCondition: {},
  privacy: {},
};
export const SettingSystem = createSlice({
  name: "setting-system",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTermCondition.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTermCondition.fulfilled, (state, action) => {
        state.loading = false;
        state.termCondition = action.payload;
      })
      .addCase(getTermCondition.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getPrivacy.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPrivacy.fulfilled, (state, action) => {
        state.loading = false;
        state.privacy = action.payload;
      })
      .addCase(getPrivacy.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = SettingSystem.actions;

export default SettingSystem.reducer;
