import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import StoreService from "services/storeService";
import Utils from "utils";
import openNotification from "utils/notification";
import cloneDeep from "lodash";

export const getStoreApi = createAsyncThunk(
  "store/getStoreApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await StoreService.storeSearchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getMyStoreApi = createAsyncThunk(
  "store/getMyStoreApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await StoreService.getMyStore(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getStoreById = createAsyncThunk(
  "store/getStoreById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await StoreService.getStoreById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const createStoreApi = createAsyncThunk(
  "store/createStoreApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await StoreService.createStore(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateStoreApi = createAsyncThunk(
  "store/updateStoreApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await StoreService.updateStore(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateStoreState = createAsyncThunk(
  "store/updateStoreState",
  async (data, { rejectWithValue }) => {
    try {
      const response = await StoreService.updateStoreState(data);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const delStoreApi = createAsyncThunk(
  "store/delStoreApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await StoreService.delStore(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  storeList: [],
  storeDetail: {},
  myStoreList: []
};
export const storeSlice = createSlice({
  name: "storeSlice",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setStoreDetail: (state) => {
      state.storeDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoreApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStoreApi.fulfilled, (state, action) => {
        state.loading = false;
        state.storeList = action.payload;
      })
      .addCase(getStoreApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getMyStoreApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyStoreApi.fulfilled, (state, action) => {
        state.loading = false;
        state.myStoreList = action.payload;
      })
      .addCase(getMyStoreApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getStoreById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStoreById.fulfilled, (state, action) => {
        state.loading = false;
        state.storeDetail = action.payload;
      })
      .addCase(getStoreById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createStoreApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(createStoreApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createStoreApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStoreApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(delStoreApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delStoreApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(delStoreApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreState.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStoreState.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStoreState.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setStoreDetail } = storeSlice.actions;

export default storeSlice.reducer;
