import axiosInstance from "auth/FetchInterceptor";
import { STORE_TABLE_API, STORE_TABLE_API_CREATE_MULTI } from "constants/ApiConstant";
const StoreTableService = {};

StoreTableService.getStoreDinnerTable = function (data) {
  return axiosInstance.post(`${STORE_TABLE_API}/search-grid`, data);
};
StoreTableService.getStoreDinnerTableQR = function (data) {
  return axiosInstance.post(`${STORE_TABLE_API}/qr-info`, data);
};
StoreTableService.createStoreDinnerTable = function (data) {
  return axiosInstance.post(STORE_TABLE_API, data);
};
StoreTableService.updateStoreDinnerTable = function (data) {
  return axiosInstance.put(STORE_TABLE_API, data);
};
StoreTableService.updateStoreDinnerTableLogPrint = function (data) {
  return axiosInstance.put(`${STORE_TABLE_API}/log-print`, data);
};
StoreTableService.delStoreDinnerTable = function (id) {
  return axiosInstance.delete(`${STORE_TABLE_API}/id?id=${id}`, null);
};
StoreTableService.getMonitorStoreDinnerTable = function (data) {
  return axiosInstance.post(`${STORE_TABLE_API}/monitor-table`, data);
};
StoreTableService.createMultiStoreDinnerTableApi = function (data) {
  return axiosInstance.post(STORE_TABLE_API_CREATE_MULTI, data);
};


export default StoreTableService;
