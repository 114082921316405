import { initializeApp } from "firebase/app"
import { getFirestore } from 'firebase/firestore/lite';
import isEmpty from 'lodash/isEmpty';
import { 
	getAuth, 
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut,
	signInWithPopup,
	GoogleAuthProvider,
	FacebookAuthProvider,
	signInWithCustomToken,
} from 'firebase/auth'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import firebaseConfig from 'configs/FirebaseConfig';
import { FIREBASE_TOKEN } from 'constants/AuthConstant';
import { getMessaging, getToken, onMessage} from 'firebase/messaging';

export const firebaseConfig = {
  databaseURL: 'https://emilus.firebaseio.com',
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const currentUser = auth.currentUser
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

const validateFirebaseLogin = () => {
  const getFirebaseToken = () => {
    const firebaseToken = localStorage.getItem(FIREBASE_TOKEN);
    return firebaseToken || '';
  };

  // Validate token firebase (auth firebase)
  auth.onAuthStateChanged((user) => {
    if (user) {
      // User is signed in.
      console.log("us",user);
    } else {
      // No user is signed in.
      const firebaseToken = getFirebaseToken();
      if (!isEmpty(firebaseToken)) {
        signInWithCustomToken(auth, firebaseToken)
          .then((userCredential) => {
            // Signed in
            // const {user} = userCredential;
            console.log("a",userCredential);
          })
          .catch((error) => {
            console.log(error);
            // const errorCode = error.code;
            // const errorMessage = error.message;
          });
      }
    }
  });
};

const messaging = getMessaging();
export const RequestForTokenAsync = async () => {
  let firebaseToken = null;
  try {
    const tokenFirebase = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY});
    firebaseToken = tokenFirebase;
  } catch (error) {
    console.log(error);
  }
  return firebaseToken;
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export {
	db,
	auth,
	currentUser,
	googleAuthProvider,
	GoogleAuthProvider,
	facebookAuthProvider,
	FacebookAuthProvider,
	signInWithEmailAndPassword,
	signOut,
	signInWithPopup,
	createUserWithEmailAndPassword,
	validateFirebaseLogin,
};



export default firebaseApp;