import axiosInstance from "../auth/FetchInterceptor";
import {
    SEARCH_ORDER,
    EXPORT_EXEL_ORDER,
    SEARCH_ORDER_DETAIL,
    TABLE_SEARCH_ORDER,
    TABLE_SEARCH_EMPLOYEE_ORDER,
    ORDER_CHANGE_STATUS_ALL,
    ORDER_REJECT,
    ORDER_PAYMENT_CONFIRM,
    ORDER_PAYMENT_REQUEST,
    ORDER_CONFIRM,
    ORDER_CANCEL_ITEM,
    ORDER_CHANGE_STATUS,
    ORDER_UPDATE_EMPLOYEE,
    STORE_PRODUCT,
    ORDER_ADD_ITEM,
    ORDER_CREATE_EMPLOYEE,
    TABLE_SUMMARY_EMPLOYEE_ORDER,
    ORDER_ITEM_PRINT_STATUS,
    ORDER_ITEM_ADD_EXTERNAL,
    ORDER_ITEM_UPDATE_EXTERNAL,
    TABLE_SEARCH_PRODUCT_ORDER,
    PRODUCT_ITEM_UPDATE_ALL,
    ORDER_EXTRACT,
    ORDER_GROUP,
    ORDER_CHANGE_CASH,
    CHANGE_ORDER_TYPE,
    ORDER_PRE_PAYMENT,
    SORT_ORDER_ITEMS,
    TABLE_SEARCH_EMPLOYEE_ORDER_V2,
    UPDATE_ORDER_PAID,
} from "constants/ApiConstant";

const OrderService = {}

OrderService.OrderSearch = function (data) {
    return axiosInstance.post(SEARCH_ORDER, data);
};

OrderService.OrderSearchProduct = function (data) {
    return axiosInstance.post(TABLE_SEARCH_PRODUCT_ORDER, data);
};

OrderService.getOrderDetail = function (id) {
    return axiosInstance.get(`${SEARCH_ORDER_DETAIL}/${id}`, null);
};

OrderService.OrderExportExel = function (data) {
    return axiosInstance.post(EXPORT_EXEL_ORDER, data, {
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: "blob",
    });
};

OrderService.tableSearchOrder = function (data) {
    return axiosInstance.put(TABLE_SEARCH_ORDER, data);
};

OrderService.tableSearchEmployeeOrder = function (data) {
    return axiosInstance.put(TABLE_SEARCH_EMPLOYEE_ORDER, data);
};

OrderService.tableSummaryEmployeeOrder = function (data) {
    return axiosInstance.put(TABLE_SUMMARY_EMPLOYEE_ORDER, data);
};

OrderService.tableSearchEmployeeOrderV2 = function (data) {
    return axiosInstance.post(TABLE_SEARCH_EMPLOYEE_ORDER_V2, data);
};

OrderService.GetStoreProductByCategory = function (data) {
    return axiosInstance.post(STORE_PRODUCT, data);
};

OrderService.orderChangeStatusAll = function (data) {
    return axiosInstance.put(ORDER_CHANGE_STATUS_ALL, data);
};

OrderService.orderConfirm = function (id) {
    return axiosInstance.put(`${ORDER_CONFIRM}/${id}`, null);
};

OrderService.orderReject = function (id) {
    return axiosInstance.put(`${ORDER_REJECT}/${id}`, null);
};

OrderService.orderPaymentConfirm = function (data) {
    return axiosInstance.put(`${ORDER_PAYMENT_CONFIRM}/${data.id}`, data);
};

OrderService.orderPaymentRequest = function (data) {
    return axiosInstance.put(`${ORDER_PAYMENT_REQUEST}/${data.id}`, data);
};

OrderService.cancelOrderItem = function (id) {
    return axiosInstance.put(`${ORDER_CANCEL_ITEM}/${id}`, null);
};

OrderService.changeStatusOrderItem = function (data) {
    return axiosInstance.put(`${ORDER_CHANGE_STATUS}`, data);
};

OrderService.changeOrderCustomer = function (data) {
    return axiosInstance.put(`${ORDER_UPDATE_EMPLOYEE}`, data);
};

OrderService.createOrderItem = function (data) {
    return axiosInstance.post(`${ORDER_ADD_ITEM}`, data);
};

OrderService.creatEItemExternal = function (data) {
    return axiosInstance.post(ORDER_ITEM_ADD_EXTERNAL, data);
};

OrderService.updateItemExternal = function (data) {
    return axiosInstance.put(ORDER_ITEM_UPDATE_EXTERNAL, data);
};



OrderService.createOrder = function (data) {
    return axiosInstance.post(ORDER_CREATE_EMPLOYEE, data);
};

OrderService.changePrintStatus = function (id) {
    return axiosInstance.post(`${ORDER_ITEM_PRINT_STATUS}/${id}`, null);
};

OrderService.changeProductStatus = function (data) {
    return axiosInstance.post(PRODUCT_ITEM_UPDATE_ALL, data);
};

OrderService.extractOrder = function (data) {
    return axiosInstance.post(ORDER_EXTRACT, data);
};

OrderService.groupOrder = function (data) {
    return axiosInstance.post(ORDER_GROUP, data);
};

OrderService.changeCashOrder = function (data) {
    return axiosInstance.put(ORDER_CHANGE_CASH, data);
};

OrderService.changeTypeOrder = function (data) {
    return axiosInstance.put(CHANGE_ORDER_TYPE, data);
};


OrderService.prePaymentOrder = function (data) {
    return axiosInstance.post(ORDER_PRE_PAYMENT, data);
};

OrderService.getOrderSetting = function (data) {
    return axiosInstance.post(CHANGE_ORDER_TYPE, data);
};

OrderService.sortOrderItems = function (data) {
    return axiosInstance.put(SORT_ORDER_ITEMS, data);
};

OrderService.updateOrderPaid = function (id) {
    return axiosInstance.put(`${UPDATE_ORDER_PAID}/${id}`, null);
};

export default OrderService;