import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SettingService from "services/SettingService";
import openNotification from "../../utils/notification";
import Utils from "../../utils";

export const getAllGroupCate = createAsyncThunk(
  "setting/getAllGroupCate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.GetAllGroupCate();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addGroupCate = createAsyncThunk(
  "setting/addGroupCate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.AddGroupCate(data);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateGroupCate = createAsyncThunk(
  "setting/updateGroupCate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SettingService.UpdateGroupCate(data);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteGroupCate = createAsyncThunk(
  "setting/deleteGroupCate",
  async (id, { rejectWithValue }) => {
    try {
      const response = await SettingService.DeleteGroupCate(id);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  dataList: [],
  dataSource: [],
};
export const settingGroupCateSlice = createSlice({
  name: "settingGroupCate",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setDataList: (state, {payload}) => {
      state.dataList = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGroupCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllGroupCate.fulfilled, (state, action) => {
        state.loading = false;
        state.dataList = action.payload;
        state.dataSource = action.payload;
      })
      .addCase(getAllGroupCate.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setDataList } = settingGroupCateSlice.actions;

export default settingGroupCateSlice.reducer;
